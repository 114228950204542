import axios from 'axios';
import React, { Component} from 'react'
import { 
    Modal, 
    Button,
    Image,
    Header
} from 'semantic-ui-react'

export default class ShowModal extends Component {    
    constructor(props) {
        super(props)
        this.state= {
            'axios_status': {
                'fetching': false,
                'fetch_error': false,
                'fetch_error_message': null
            },
        }
    }
    submit_one_click_payment = async () => {
        console.log('submit_one_click_payment - state' + this.state)
        this.setState(prevState => ({
            axios_status: {
              ...prevState.axios_status,           // copy all other key-value pairs of axios_status object
            fetching: true
            }
        }))
        const response = await axios.get(this.props.billing_details.ajax_billing_endpoint)
        .catch(function(error){
            if (error.response){
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                this.setState(prevState => ({
                    axios_status: {
                        ...prevState.axios_status,           // copy all other key-value pairs of axios_status object
                        fetching: false,
                        fetch_error: true,
                        fetch_error_message: error.response.data
                    }
                }))
            }
        })
        console.log('response was: ', response)
        if (response.data.status === "true" || response.data.status === true){
            console.log('billed successfully')
            // force the window to refresh after 10 seconds...
            setTimeout(function(){
                window.location.reload();
            }, 5000);
        } else {
            console.log('ERROR!!! failed to charge for photodelivery!')
            console.log('ERROR!!! Message received when attempting one-click payment', response.data.message)
        }

    }
    viewClick = (link) => {
        window.open(link, "_blank", "noreferrer");
    }
    one_click_payment = () => {
        if (this.props.billing_details.ajax_billing_endpoint !== false){
            return (
                <Button color='blue' fluid={true} onClick={() => this.submit_one_click_payment()}>
                    One Click Payment with card on file
                </Button>
            )
        }
    }
    manual_payment = () => {
        if (this.props.billing_details.hasOwnProperty('link_manual_payment') && this.props.billing_details.link_manual_payment !== false){
            // then the object exists, and a button should be displayed...
            return (
                <Button fluid={true} onClick={() => this.viewClick(this.props.billing_details.link_manual_payment)}>
                    Manually pay with a card through your Hommati dashboard
                </Button>
            )
        } else {
            console.log('problem with manual payment link?', this.props.billing_details)
            return (
                <Button color='red' fluid={true} onClick={() => this.viewClick(this.props.billing_details.link_update_profile)}>
                    There was an error processing available payment options for this delivery.  <br/>Please click the "Manage Payment Methods" button below, or click this button to update your agent profile.
                </Button>
            )
        }
    }
    manage_payment_methods = () => {
        console.log('in manage_payment_methods', this.props.billing_details.link_manage_payment_methods)
        if (this.props.billing_details.hasOwnProperty('link_manage_payment_methods')){
            return (
                <Button fluid={true} onClick={() => this.viewClick(this.props.billing_details.link_manage_payment_methods)}>
                    Manage payment methods
                </Button>
            )
        }
    }
    payment_methods = () => {
        if (this.props.billing_details.status_is_paid_for === false && this.props.billing_details.status_is_deferred_billing === false){
            return (
                <div>
                    {this.one_click_payment()}
                    {this.manual_payment()}
                    {this.manage_payment_methods()}
                </div>
            )
        }
    }
    render(){
        return (
            <Modal
                closeIcon
                centered={true}
                open={this.props.open}
                onClose={() => this.props.close_modal()}
            >
            <Modal.Header>Paying for your photo delivery</Modal.Header>
            <Modal.Content image>
                <Image size='medium' src='https://static-assets.hommati.com/hommati-logo-svg-cropped.svg' wrapped />
                <Modal.Description>
                    <Header>There are several payment methods available to you:</Header>
                    <p>This order has not yet been paid for.  Once payment has been made, all download functionality will be enabled, and the watermarks will be removed.</p>
                    <p><em>IMPORTANT:</em>After paying, it may take up to a minute for watermarks to be removed.  Please hit the refresh button on your browser after paying to see changes.</p>
                    <p>To continue, choose one of the options below:</p>                
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                {this.payment_methods()}
            </Modal.Actions>
            </Modal>
        )
    }
}